@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Handlee&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

* {
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-image: linear-gradient(to right top, #000000, #040404, #080808, #0c0c0c, #0f0f0f);
  background-color: #000000;
}

